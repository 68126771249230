import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Column = makeShortcode("Column");
const Box = makeShortcode("Box");
const Video = makeShortcode("Video");
const List = makeShortcode("List");
const Link = makeShortcode("Link");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "everything-is-dancing",
      "style": {
        "position": "relative"
      }
    }}>{`Everything Is Dancing`}<a parentName="h1" {...{
        "href": "#everything-is-dancing",
        "aria-label": "everything is dancing permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <Column sx={{
      gap: "10%"
    }} mdxType="Column">
      <Box sx={{
        padding: 2
      }} mdxType="Box">
        <p><strong parentName="p">{`From Breath`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/NOSsDHLooi0" mdxType="Video" />
      </Box>
      <Box sx={{
        padding: 2
      }} mdxType="Box">
        <p><strong parentName="p">{`To Solo Underscores in the Office`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/BFWRF0WXkAs" mdxType="Video" />
      </Box>
    </Column>
    <p>{`We collect on this page a curated set of the best videos available for free online to try and provide a broader perspective of what dance is and can be. In truth, everything is always dancing: from the electron turning perpetually in its orbital devotion, to the blood in your veins faithfully keeping the beat of "you", to the earth spiralling through spacetime in vast systems of solar harmony, to the galaxies which make up the great sky, to the universe itself: always expanding in ever more maginificent movements of its own self-realisation.`}</p>
    <List mdxType="List">
      <Link to="https://www.youtube.com/watch?v=_vHqIMFDbQI" mdxType="Link">
        <p><strong parentName="p">{`TRIO A - Yvonne Rainer`}</strong>{`  `}</p>
        <p>{`This performance changed the way the audience was invited to see dance, as it stripped away all the spectacle and reduced it to what it is: movement.`}</p>
      </Link>
      <Link to="https://www.youtube.com/watch?v=xgf3xgbKYko" mdxType="Link">
        <p><strong parentName="p">{`Lamentations - Martha Graham`}</strong>{`  `}</p>
        <p>{`One of the mothers of modern dance. This includes an interesting interview with Martha and an excerpt from her groundbreaking solo from 1936.`}</p>
      </Link>
      <Link to="https://www.youtube.com/watch?v=sDZLlHZyFdw" mdxType="Link">
        <p><strong parentName="p">{`Isadora Duncan`}</strong>{` `}</p>
        <p>{`Isadora is another mother of modern dance, and there isn't a piece of hers that is more groundbreaking than any other, but this video will give you a glimpse of her life and her method, how she managed to break away with the canon of ballet within western theater.`}</p>
      </Link>
      <Link to="https://www.youtube.com/watch?v=vlLZExpgBOY" mdxType="Link">
        <p><strong parentName="p">{`Rosas Danst Rosas  - Anna Teresa de Keersmaker`}</strong>{`  `}</p>
        <p>{`An all time personal favorite of Antonio's, Anna Teresa defined the language of mechanical repetition and imbued it with emotional intensity `}</p>
      </Link>
      <Link to="https://www.youtube.com/watch?v=4qjvGIMeIhU" mdxType="Link">
        <p><strong parentName="p">{`L’apres midi d’un faun - Nijinsky`}</strong>{`  `}</p>
        <p>{`This performance is part of the repertory of `}<em parentName="p">{`Le Ballet Russes`}</em>{`, which was a dance company in the beginning of the 20th century that broke many stereotypes about what is "allowed" to be seen on stage. In this particular piece, homoeroticism and fascination with the male body and its beauty are front and centre.`}</p>
      </Link>
      <Link to="https://www.youtube.com/watch?v=1IVG_vLHtw4" mdxType="Link">
        <p>{`The Basic Neurocellular Patterns (BNP) - Bonnie Bainbridge Cohen`}</p>
        <p>{`The BNP are potential patterns of movement inherent first in the movement of fluid through the cellular membranes and then recorded and organized in the nervous system. The BNP are stimulated into existence through relationship and interaction with the environment. They are called forth based on the relative simplicity or complexity of their structure and function and the supporting and challenging conditions in the environment.`}</p>
      </Link>
      <Link to="https://www.youtube.com/watch?v=JCWQPntzlh0" mdxType="Link">
        <p>{`Mindful Movement: The Evolution of the Somatic Arts and Conscious Action - Martha Eddy`}</p>
        <p>{`Martha Eddy uses original interviews, case studies, and practice-led research to define the origins of a new holistic field—somatic movement education & therapy—and its impact on fitness, ecology, politics, health, education and performance. Her book reveals the role the performing arts and psychology have played in informing and inspiring the historical and cultural narrative of various somatic arts, which is an approach to consciousness based in the awareness of the soma, i.e. the living body.`}</p>
      </Link>
      <Link to="https://www.youtube.com/watch?v=0IH_rrpj0CU" mdxType="Link">
        <p><strong parentName="p">{`Birds - Merce Cunningham`}</strong>{`  `}</p>
        <p>{`Merce Cunningham revolutionised dance by introducing mathematical chance operation into choreography, along with his partner the composer `}{`[John Cage]`}{`. Movement phrases were constructed and then assembled by chance mechanisms and performed on stage. Many times the dancers didn't even know the costumes or the scenography until the day of the premiere. Birds is a fine example of beautiful chance.`}</p>
      </Link>
      <Link to="https://www.youtube.com/watch?v=3FALHd5Viz4" mdxType="Link">
        <p><strong parentName="p">{`Water Motor - Trisha Brown`}</strong>{`   `}</p>
        <p>{`Trisha utilises accumulation as a method of creating mirrors and vectors of movements that are all based in one simple movement phrase, being performed in different moments and directions. This is a perfect example of the infinite possibilities of movement perception.`}</p>
      </Link>
      <Link to="https://www.youtube.com/watch?v=z1hFwsXaTVY" mdxType="Link">
        <p><strong parentName="p">{`Sacre du Printemps  - Pina Bausch`}</strong>{`  `}</p>
        <p>{`Pina Bausch revolutionised the way we imagine dance by mixing it with theatrical gestures, creating the genre of "theater dance". Her abstract depictions of fairytales, struggles and romance are mesmerising  In `}<em parentName="p">{`Sacre du Printemps`}</em>{`, the theme of sacrifice becomes a sensorial experience to the viewer, intensified by the symbolic strength of theatre.`}</p>
      </Link>
      <Link to="https://www.youtube.com/watch?v=RVyh1ewep84" mdxType="Link">
        <p><strong parentName="p">{`AI Sketches for Google - Bill T Jones`}</strong></p>
        <p>{`Already introduced above, this is another very interesting documentary of Google in collaboration with Bill, creating code for motion tracking from dancers!`}</p>
      </Link>
      <Link to="https://www.youtube.com/watch?v=15URWNt58TQ" mdxType="Link">
        <p><strong parentName="p">{`Mimosa  - Trajal Harrel`}</strong>{`  `}</p>
        <p>{`Mimosa predicted Tok Tok, by it singularity and total attention on something that happens momentarily, but also by imagining the stage as a space of immediate transport to different worlds, activated by the performer's desires.`}</p>
      </Link>
      <Link to="https://www.youtube.com/watch?v=e1Db3t3f5-Q " mdxType="Link">
        <p><strong parentName="p">{`Diagonal Worlds - Bob Fosse`}</strong></p>
        <p>{`An incredibly choreographed performance well worth the few minutes it takes to watch.`}</p>
      </Link>
      <Link to="https://www.youtube.com/watch?v=aj8RFUQex7w" mdxType="Link">
        <p><strong parentName="p">{`Embedded Vlogger - Ruth Zaporah Interview`}</strong></p>
        <p>{`Another video from the `}<a parentName="p" {...{
            "href": "https://www.dance-tech.net/"
          }}>{`dance-tech`}</a>{` archives worth exploring for yourself.`}</p>
      </Link>
    </List>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      